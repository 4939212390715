import React, { useState, useEffect } from 'react';
import axios from 'axios';

const AdminConsole = () => {
  const [blogs, setBlogs] = useState([]);
  const [blogForm, setBlogForm] = useState(
    { 
      id: '', 
      title:'', 
      description: '', 
      route: '',
      date: '',
      content: '', 
      year: ''
    }
);
  const [token, setToken] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:5000/api/login', { username, password });
      setToken(response.data.accessToken);
    } catch (error) {
      console.error('Login Failed', error);
    }
  };

  const fetchBlogs = async () => {
    const response = await axios.get('http://localhost:5000/api/blogs');
    setBlogs(response.data);
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  const handleBlogSubmit = async (e) => {
    e.preventDefault();
    try {
      const filteredBlogForm = Object.fromEntries(
        Object.entries(blogForm).filter(([_, value]) => value !== '')
      );
      await axios.post('http://localhost:5000/api/blogs', filteredBlogForm, {
        headers: { Authorization: `Bearer ${token}` }
      });

      console.log("Blog has been submitted!");

      //clear all field back to empty after submission
      setBlogForm({ 
        id: '', 
        title:'', 
        description: '', 
        route: '',
        date: '',
        content: '', 
        year: ''
      });
      fetchBlogs();
    } 
    catch (error) {
      console.error('Failed to create blog', error);
    }
  };

  const handleInputChange = (e) => {
    const {name, value} = e.target;
    setBlogForm(prevState => ({
      ...prevState,
      [name]: value
    }));

  }

  if (!token) {
    return (
    <div className='text-black'>
        <form onSubmit={handleLogin} className='bg-white flex items-center justify-center min-h-screen'>
            <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            placeholder="Username"
            />
            <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            />
            <button type="submit">Login</button>
        </form>
    </div>
    );
  }
  return (
    <div className='bg-black text-white min-h-screen mt-24 '>
      <div className='bg-gray-500 m-auto items-center w-full p-4'>
        <h1 className='text-center text-black text-3xl mt-12'>Create or Update Blog</h1>
        <form onSubmit={handleBlogSubmit} className='flex flex-col items-center mt-10 space-y-6 text-black'>
          <input
            className='p-2 w-3/4'
            type="number"
            name="id"
            value={blogForm.id}
            onChange={handleInputChange}
            placeholder="id"
          />
          <input
            className='p-2 w-3/4'
            type="text"
            name="title"
            value={blogForm.title}
            onChange={handleInputChange}
            placeholder="title"
          />
          <input
            className='p-2 w-3/4'
            type="text"
            name="description"
            value={blogForm.description}
            onChange={handleInputChange}
            placeholder="description"
          />
          <input
            className='p-2 w-3/4'
            type="text"
            name="route"
            value={blogForm.route}
            onChange={handleInputChange}
            placeholder="route"
          />
          <input
            className='p-2 w-3/4'
            type="text"
            name="date"
            value={blogForm.date}
            onChange={handleInputChange}
            placeholder="date"
          />
          <textarea
            className='p-2 w-3/4'
            name="content"
            value={blogForm.content}
            onChange={handleInputChange}
            placeholder="content"
          />
          <input
            className='p-2 w-3/4'
            type="text"
            name="year"
            value={blogForm.year}
            onChange={handleInputChange}
            placeholder="year"
          />
          <button type="submit" className='bg-violet-700 hover:bg-violet-800 active:bg-violet-900 focus:ring-violet-300 text-white font-bold py-3 px-6 rounded-lg text-center'>Submit Blog</button>
        </form>
      </div>
      <div className='bg-gray-700 m-auto items-center w-full p-4'>
        <h1 className='text-center text-black text-3xl mt-12'>All Current Blogs</h1>
        <div className='grid grid-cols-3 gap-8 mt-12'>
          {blogs.map(blog => (
            <div key={blog.id} className=''>
              <h3>{blog.title}</h3>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AdminConsole;